var heightEQ = function (params) {
    params = params || {};
    params.base = params.base || ".fullHeight";
    params.target = params.target || "data-height-eq";

    this.params = params;
    this.init();

};

heightEQ.prototype.init = function () {
    this.addEvents();
};


heightEQ.prototype.run = function () {
    this.getElements().map(function (item) {
        this.setHeight(item)
    }.bind(this))
};


heightEQ.prototype.addEvents = function () {
    window.addEventListener('resize', function () {
        this.run();
    }.bind(this));

    document.addEventListener('DOMContentLoaded', function () {
        this.run();
    }.bind(this));
};

heightEQ.prototype.getElements = function () {
    return Array.prototype.slice.call(document.querySelectorAll(this.params.base));
};

heightEQ.prototype.setHeight = function (item) {

    item.style.height = "";
    document.querySelector(item.getAttribute(this.params.target)).style.height = document.querySelector(item.getAttribute(this.params.target)).getBoundingClientRect().height + 'px';
    item.style.height = document.querySelector(item.getAttribute(this.params.target)).getBoundingClientRect().height + 'px';
    document.querySelector(item.getAttribute(this.params.target)).style.height = '';

};

var h = new heightEQ();