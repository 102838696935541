var parallax = function (params) {
    let state = true;

    params.targets.map((target)=> {
        try {
            document.querySelector(target).style.top;
        } catch (e) {
            state = false;
        }
    });

    if (state) {


        this.elements = this.getElements(params.targets);
        this.params = params || {};
        this.init();

    }
};
parallax.prototype.getElements = function (targets) {

    let elements = [];

    targets.map((element) => {
        elements.push(document.querySelector(element));
    });

    return elements;

};


parallax.prototype.init = function () {

    window.addEventListener('scroll', function () {

        let offset = this.params.ratio;
        let currentTop = document.body.scrollTop;

        this.elements.map((target)=> {
            target.style.top = currentTop * offset + "px";
            this.params.extendFunction ? this.params.extendFunction(target, currentTop, offset) : null;
            offset = offset * this.params.difference;
        });

    }.bind(this));

};


let stickyMenu = function (menuclass) {
    let menuClass = menuclass || 'menu';
    this.menustrips = document.getElementsByClassName(menuClass)[0].parentNode;
    this.offset = 101;
    this.pageScrollHeight = document.body.clientHeight - window.innerHeight;
    this.init();
    setInterval(function () {
        this.pageScrollHeight = document.body.clientHeight - window.innerHeight;
    }.bind(this))
};

stickyMenu.prototype.init = function () {
    this.scrollProgress(this.menustrips);

    window.addEventListener('scroll', function() {

        ((document.body.getBoundingClientRect().top + 1 ) * -1) + 1 > this.offset
            ? this.menustrips.classList.add('sticky')
            : $(this.menustrips).removeClass('sticky');

        this.scollProgress.style.width = ( window.scrollY / this.pageScrollHeight ) * 100 + '%';

    }.bind(this));

};

stickyMenu.prototype.scrollProgress = function(target) {

    let ScrollBar = create('div', target);
        ScrollBar.addClass('scrollProgress');

    let style = ScrollBar.style;

    style.position = 'absolute';
    style.height = '2px';
    style.marginTop = '-2px';
    style.left = '0';
    style.position = 'fixed';
    style.background = '#e4e4e4';
    style.zIndex = '12';

    style.width = (document.body.scrollTop / this.pageScrollHeight) * 100 + '%';

    this.scollProgress = ScrollBar;

};

$(document).ready(function (e) {
    if ($(window).width() > 767 ) {
        let menuclass = document.querySelectorAll('.menudark').length > 0 ? 'menudark' : 'menu' ;
        new stickyMenu(menuclass)
    }
});

window.addEventListener('DOMContentLoaded', function () {

    var prllx = new parallax({
        targets: [
            '.squaresvg',
            '.octagonsvg',
            '.circlesvg'
        ],
        ratio: 0.5,
        difference: 0.7
    });

    var bubbles = new parallax({
        targets: Array.prototype.slice.call(document.querySelectorAll('.headerbackground .bubbles img')),
        ratio: 1,
        difference: 0.7
    });


    /*var Title = new parallax({
        targets: [
            '.headercontent h1'
        ],
        ratio: 0.3,
        difference: 0.7,
        extendFunction: function (htmlElement, currentScroll, offsetRatio) {

            htmlElement.parentNode.style.perspective = '1200px';
            htmlElement.style.transform = 'rotateX(' + currentScroll * -0.5 * offsetRatio + 'deg)';

        }
    });*/

    //new animatePortfolio();

    /* Every time the window is scrolled ... */
    $(window).scroll( function(){

        /* Check the location of each desired element */
        $('.hideme').each( function(i){

            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            console.log(bottom_of_object, bottom_of_window);

            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object ){


                //$(this).addClass('animateme');
                $(this).animate({'opacity':'1'},1000);

            }

        });

    });

});

function reveal() {
    const reveals = document.querySelectorAll(".reveal");
    for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

window.addEventListener("scroll", reveal);

reveal();
