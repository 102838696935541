/**
 * Created by feher on 2016.10.03..
 */

let animatePortfolio = function () {

    let parseElementList = (condition = false) => {

        //todo: provider-t megnézni
        let scaleProvider = $('provider').css('content');
        let firstElements;
        switch (scaleProvider){
            case '0':
                firstElements = 3;
                break;
            case '1':
                firstElements = 3;
                break;
            case '2':
                firstElements = 2;
                break;
            case '3':
                firstElements = 1;
                break;
        }

        let element = document.querySelectorAll('.grid-item');

        //if (condition)
        //    element = element.filter((item) => {
        //        return $(item).hasClass(condition);
        //    });

        element = Array.prototype.slice.call(element);

        return element;

    };

    let removeActiveClass = () => {
        let itemArray = Array.prototype.slice.call(document.querySelectorAll('.grid-item'));
        itemArray.map((item)=> {
            $(item).removeClass('animated');
        });
        return itemArray;
    };
    let addActiveClass = () =>{
        parseElementList().map((item) =>{
            $(item).addClass('animated');
        });
    };

    let refreshClasses = (condition = false) => {
        removeActiveClass();
        setTimeout(addActiveClass(condition),1500);
    };

    let initInViewWaypoints = () => {
        addActiveClass();
        let waypoints = [];
        parseElementList().map((item) =>{

            console.log(item);
            let waypoint = new Waypoint.Inview({
                element: $(item)[0],
                offset: -200,

                entered: function ()  {
                    item.classList.add('isSown');
                },

                enter: function ()  {
                    item.classList.add('isSown');
                },

                exit: function (direction){
                    if (direction === 'up')
                    item.classList.remove('isSown');
                },

                exited: function (direction){
                    if (direction === 'up')
                    item.classList.remove('isSown');
                }
            });
        });
    };

    //initInViewWaypoints();
    addActiveClass();

    $('.isotope').isotope({});
    let activeClasses = [];
    $('.iso-buttons .button').on('click',  function(e){

        let current = $(this);
        let buttons = $('.iso-buttons .button');

        let hasActive = () => {

            if (current.hasClass('active')) {
                buttons.removeClass('active');
                current.parent().removeClass('filter-has-active')
            } else {
                buttons.removeClass('active');
                current.addClass('active');
                current.parent().addClass('filter-has-active')
            }
        };

        let collectActiveFilters = function(current) {

            let ret = '';
            Array.prototype.slice.call(document.querySelectorAll('.iso-buttons .active')).map((item, key) => {
                key != Array.prototype.slice.call(document.querySelectorAll('.iso-buttons .active')).length - 1
                    ? ret = ret + '.' + item.getAttribute('data-filter') + ', '
                    : ret = ret + '.' + item.getAttribute('data-filter');
            });

            let a = ret.toString();
            console.log(a);
            return a;

        };

        hasActive();
        let sort = function () {

            $('.isotope').isotope({
                filter: collectActiveFilters(current),
                layoutMode: 'fitRows'
            });
        };
        sort();
    })

    $(window).on('scroll', function (e) {
        $('.isotope').isotope();
    })
};

