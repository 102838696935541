/**
 * Created by feher on 2016.07.01..
 */
"use strict";

var create = function() {

    // var args = Array.prototype.slice.call(arguments);

    for (var i in arguments) {
        if (arguments[i] && arguments[i].constructor == String) {
            var tagName = arguments[i];
        }
        else if (arguments[i] instanceof Element) {
            var parent = arguments[i];
        }
        else if (arguments[i] && arguments[i].constructor == Object) {
            var properties = arguments[i];
        }
    }

    if (!tagName)
        return undefined;

    var element = document.createElement(tagName);

    if (parent)
        element.parent = parent;

    if (properties) {
        for (var propName in properties) {
            element[propName] = properties[propName];
        }
    }

    return element;
};

var arraySlice =  function (element) {
    return Array.prototype.slice.call(element);
};

Object.defineProperty(Element.prototype, 'parent', {

    get: function(){
        return this.parentElement;
    },

    set: function(p){
        p.appendChild(this);
    }
});

Element.prototype.getMaxZIndex = function() {
    var maxZIndex = 0;
    var o = this;
    while (o.nodeName.toUpperCase() != 'HTML') {
        var computedStyle = window.getComputedStyle(o);
        if (!('zIndex' in computedStyle))
            continue;
        var computedZIndex = parseInt(computedStyle.zIndex);
        maxZIndex = computedZIndex > maxZIndex ? computedZIndex : maxZIndex;
        o = o.parentNode;
    }
    return maxZIndex;
};

if (!Element.prototype.remove) {
    Element.prototype.remove = function() {
        if (this.parentNode) this.parentNode.removeChild(this);
    };
}

Element.prototype.show = function(){
    this.removeClass('Hidden');
    return this;
};

Element.prototype.hide = function(){
    this.addClass('Hidden');
    return this;
};

Element.prototype.addClass = function(){
    for (var i in arguments)
        this.classList.add(arguments[i]);
    return this;
};

Element.prototype.removeClass = function(className) {
    for (var i in arguments)
        this.classList.remove(arguments[i]);
    return this;
};

Element.prototype.setText = function(text) {
    this.textContent = text;
    return this;
};

Element.prototype.setTitle = function(title) {
    this.title = title;
    return this;
};

Element.prototype.setAlt = function(alt) {
    this.alt = alt;
    return this;
};

HTMLButtonElement.prototype.setImg = function(src) {
    create('img', this, {src: src});
    return this;
};